import './App.css';
import MenuCloseImg from "./assets/images/menu-close.png";
import TinyPetsMenuImg from "./assets/images/tinypets-menu.png";
import FeedbackMenuImg from "./assets/images/feedback-menu.png";
import FacebookMenuImg from "./assets/images/facebook-menu.png";
import TwitterMenuImg from "./assets/images/twitter-menu.png";
import InstagramMenuImg from "./assets/images/instagram-menu.png";
import TiktokMenuImg from "./assets/images/tiktok-menu.png";

function Menu({showMenu,showFeedback}) {

  return (
    <div className="App-menu">
      <img alt="menu" onClick={()=>{showMenu(false)}} className="menu-close" src={MenuCloseImg} />
      <div style={{color:"#00A591",textAlign:"left",padding:0,margin:0}}> Menu </div>
      <div onClick={()=>{window.open("https://www.kickstarter.com/projects/tinypets/tiny-pets-exercise-grow-and-collect-cute-pets","_blank")}}><img alt="menu" className="menu-img" src={TinyPetsMenuImg}/>Tiny Pet Kickstarter</div>
      <div onClick={()=>{showFeedback(); showMenu(false)}}><img alt="menu" className="menu-img" src={FeedbackMenuImg}/>Give us Feedback</div>
      <hr/>
      <div onClick={()=>{window.open("https://www.facebook.com/TheTinyPetsWorld","_blank")}}><img alt="menu" className="menu-img" src={FacebookMenuImg}/>TinyPets Facebook</div>
      <div onClick={()=>{window.open("https://twitter.com/TheTinyPets","_blank")}}><img alt="menu" className="menu-img" src={TwitterMenuImg}/>Tinypets Twitter</div>
      <div onClick={()=>{window.open("https://www.instagram.com/tinypetsworld/","_blank")}}><img alt="menu" className="menu-img" src={InstagramMenuImg}/>TinyPets Instagram</div>
      <div onClick={()=>{window.open("https://www.tiktok.com/@the_tiny_pets","_blank")}}><img alt="menu" className="menu-img" src={TiktokMenuImg}/>TinyPets Tiktok</div>
      {/*
      <div onClick={()=>{
        localStorage.removeItem("uuid");
        window.location.reload();
        }}><img alt="menu" className="menu-img" src={FeedbackMenuImg}/>Reset</div>
      */}
    </div>
  );
}

export default Menu;
