import './App.css';
import MenuCloseImg from "./assets/images/menu-close.png";
import MaxImg from "./assets/images/max-stats.png";
import MiyaImg from "./assets/images/miya-stats.png";

function Stats({show,character,stats,gameResult}) {

  gameResult = gameResult || {};
  let dogpicks = (stats && stats.dog && stats.dog.picks) ? stats.dog.picks : 0;
  let catpicks = (stats && stats.cat && stats.cat.picks) ? stats.cat.picks : 0;
  let dogwins = (stats && stats.dog && stats.dog.wins) ? stats.dog.wins : 0;
  let catwins = (stats && stats.cat && stats.cat.wins) ? stats.cat.wins : 0;
  let totalPicks = dogpicks + catpicks;
  let totalWIns = dogwins + catwins;

  return (
    <div className="App-result">
      <div className="modal-bg"></div>
      <div className="inner">
      <div style={{zIndex:999}}>
          <img alt="menu" onClick={() => { show(false) }} className="result menu-close" src={MenuCloseImg} />
          <div style={{ fontSize: 10, color: "#00A591" }}>My Statistics</div>
          <br />
          <div className="stats-row">
            <div>
              <div className="stat-val">{totalPicks}</div>
              <div className="stat-name">Played</div>
            </div>
            <div>
              <div className="stat-val">{Math.trunc((totalWIns/totalPicks)*100)}%</div>
              <div className="stat-name">Win%</div>
            </div>
            <div>
              <div className="stat-val">1</div>
              <div className="stat-name">Current Streak</div>
            </div>
            <div>
              <div className="stat-val">1</div>
              <div className="stat-name">Max Streak</div>
            </div>
          </div>
          <br/>
          <div className="stats-char-box">
            <div>
              <img alt="img" src={MaxImg} />
              <div className="stats-sub-box">
                  <div>
                    <div>{stats && stats.dog && stats.dog.picks}</div>
                    <span style={{fontSize:6}}>Played</span>
                  </div>
                  <div>
                    <div>{stats && stats.dog && stats.dog.wins}</div>
                    <span style={{fontSize:6}}>Win</span>
                  </div>
              </div>
            </div>
            <div>
              <img alt="img" src={MiyaImg} />
              <div className="stats-sub-box">
                <div>
                  <div>{stats && stats.cat && stats.cat.picks}</div>
                  <span style={{ fontSize: 6 }}>Played</span>
                </div>
                <div>
                  <div>{stats && stats.cat && stats.cat.wins}</div>
                  <span style={{ fontSize: 6 }}>Win</span>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div style={{ fontSize: 8, color: "#4A2726", marginBottom: 10 }}>Support us on kickstarter!</div>
          <div
            onClick={() => { window.open("https://www.kickstarter.com/projects/tinypets/tiny-pets-exercise-grow-and-collect-cute-pets", "_blank") }}
            className="share-btn">Support</div>
        </div>
      </div>
    </div>
  );
}

export default Stats;
