import './App.css';
import MenuCloseImg from "./assets/images/menu-close.png";
import KickstarterBannerImg from "./assets/images/kickstarter-banner.png";

function About({show,character}) {

  return (
    <div className="App-modal">
      <div className="modal-bg"></div>
      <div className="inner">
      <div style={{zIndex:999}}>
          <img alt="menu" onClick={() => { show(false) }} className="result menu-close" src={MenuCloseImg} />
          <div style={{fontSize:10,color:"#00A591"}}>About TinyPets</div>
          <br/>
          <img alt="img" style={{ maxWidth:"100%"}} src={KickstarterBannerImg} />
          <br/>
          <br/>
          <div style={{fontSize:8,color:"#4A2726"}}>TinyPets is a new fitness mobile game that allows you to exercise, grow and collect cute pets. We are on kickstarter now.</div>

          <br/>
          <br/>
          <div style={{fontSize:8,color:"#4A2726",marginBottom:10}}>Support us on kickstarter!</div>
          <div
            onClick={() => { window.open("https://www.kickstarter.com/projects/tinypets/tiny-pets-exercise-grow-and-collect-cute-pets","_blank")}}
            className="share-btn">Support</div>
      </div>
      </div>
    </div>
  );
}

export default About;
