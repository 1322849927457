import './App.css';
import CongratsImg from "./assets/images/congrats.png";
import MenuCloseImg from "./assets/images/menu-close.png";
import MiyaImg from "./assets/images/miya.png";
import HeartImg from "./assets/images/heart.png";
import BrokenHeartImg from "./assets/images/broken-heart.png";
import MaxImg from "./assets/images/max.png";
import CryImg from "./assets/images/cry.png";
import OhnoImg from "./assets/images/ohno.png";
import DogSadImg from "./assets/images/dog-sad.png";
import CatSadImg from "./assets/images/cat-sad.png";

import DogEmojiImg from "./assets/images/dog-emoji.png";
import CatEmojiImg from "./assets/images/cat-emoji.png";

import { useEffect } from "react";

function Result({ showResult, character, stats, gameResult, gameInfo, setPage, playerStats, failed, answer }) {

  gameResult = gameResult || {};

  let dogwins = (stats && stats.dog && stats.dog.wins) ? stats.dog.wins : 0;
  let catwins = (stats && stats.cat && stats.cat.wins) ? stats.cat.wins : 0;
  let total = dogwins + catwins;

  let emoji = (character !== "cat") ? CatEmojiImg : DogEmojiImg;
  let shareEmoji = (character !== "cat") ? "🐶" : "🐱";

  useEffect(() => {
    const element = document.getElementById("result-inner");
    if(element) element.scrollTop = element.scrollHeight;
  });

  return (
    <div className="App-result">
      <div className="modal-bg"></div>
      <div className="inner" id="result-inner">
        <div style={{ zIndex: 999 }}>
              <img alt="menu" onClick={() => { showResult(false); setPage("start") }} className="result menu-close" src={MenuCloseImg} />
          {failed &&
            <div>
              <img alt="img" style={{ height: 60 }} src={OhnoImg} />
              <br/>
              <div className="selection-balloon">Oh no. The answer is <br/>{answer}. Try again<br />tomorrow!</div>
              <br/>
              <span>
                {character === "cat" && <img style={{ height: 178 }} alt="img" src={CatSadImg} />}
                {character === "dog" && <img style={{ height: 178 }} alt="img" src={DogSadImg} />}
              </span>
            </div>}
          {!failed &&
            <div>
              <img alt="img" style={{ height: 80 }} src={CongratsImg} />
              <br />
              <div className="selection-balloon">The answer is {answer}.<br />Please play with me again tomorrow!</div>
              <br />
              <span>
                {character === "cat" && <img style={{ height: 178 }} alt="img" src={MiyaImg} />}
                {character === "dog" && <img style={{ height: 178 }} alt="img" src={MaxImg} />}
              </span>
              <br />
            </div>}
          <div
            onClick={() => {
              let text = `TinyPetsGpt Day #${gameInfo.day} 🐾 ${gameResult.attempts}/6 \n`;
              // eslint-disable-next-line
              [...Array(gameResult.attempts)].map((v, i) => {
                for (let v = 0; v <= i; v++) {
                  if (i === gameResult.attempts - 1 && v === i) {
                    text += (gameResult.status === "lost" ? "💔" : shareEmoji) + "";
                  } else {
                    text += "💔";
                  }
                }
                for (let k = 0; k < 5 - i; k++) {
                  text += "️❤";
                }
                text += "\n";
              });

              text += "Play with me ➡️ https://tinypets.xyz/gpt";
              console.log(text);
              if (navigator.share) {
                navigator.share({
                  title: 'Tinypets GPT',
                  text: text
                }).then(() => {
                  console.log('Thanks for sharing!');
                })
                  .catch(console.error);
              } else { }
            }}
            className="share-btn">Share</div>
          <h3 style={{ fontSize: 10 }}>Day #{gameInfo.day} <span style={{ fontSize: 15 }}>🐾 </span> - {gameResult.attempts}/6</h3>
          <div className='results hearts'>
            {[...Array(gameResult.attempts)].map((v, i) => {
              let ii = i + 1;
              return <div>
                <img alt="broken" src={(gameResult.attempts > 0) ? (gameResult.attempts === 1 && gameResult.status === "won" ? emoji : BrokenHeartImg) : HeartImg} />
                <img alt="broken" src={(gameResult.attempts > 1 && ii > 1) ? (ii === gameResult.attempts && gameResult.attempts && gameResult.attempts === 2 && gameResult.status === "won" ? emoji : BrokenHeartImg) : HeartImg} />
                <img alt="broken" src={(gameResult.attempts > 2 && ii > 2) ? (ii === gameResult.attempts && gameResult.attempts === 3 && gameResult.status === "won" ? emoji : BrokenHeartImg) : HeartImg} />
                <img alt="broken" src={(gameResult.attempts > 3 && ii > 3) ? (ii === gameResult.attempts && gameResult.attempts === 4 && gameResult.status === "won" ? emoji : BrokenHeartImg) : HeartImg} />
                <img alt="broken" src={(gameResult.attempts > 4 && ii > 4) ? (ii === gameResult.attempts && gameResult.attempts === 5 && gameResult.status === "won" ? emoji : BrokenHeartImg) : HeartImg} />
                <img alt="broken" src={(gameResult.attempts > 5 && ii > 5 && gameResult.status === "lost") ? CryImg : ((gameResult.attempts === 6 && ii > 5) ? emoji : HeartImg)} />
              </div>
            })}
          </div>
          <div className="today-wins">
            <h3>Today Wins!</h3>
            <div className="today-wins-item">
              <div className="result-bar"
                style={{
                  backgroundColor: (catwins > dogwins) ? "#F6C5A6" : "#8DC4E4",
                  height: dogwins / total * 50 + "%"
                }}
              ></div>
              <div>{dogwins}</div>
            </div>
            <div className="today-wins-item">
              <img alt="img" src={MaxImg} />
            </div>
            <div className="today-wins-item" style={{ marginLeft: 50 }}>
              <div className="result-bar" style={{
                backgroundColor: (catwins < dogwins) ? "#F6C5A6" : "#8DC4E4",
                height: catwins / total * 50 + "%"
              }}></div>
              <div>{catwins}</div>
            </div>
            <div className="today-wins-item">
              <img alt="img" src={MiyaImg} />
            </div>
          </div>
          <br />
          <div 
          onClick={()=>{
            setPage("chat");
            showResult(false);
          }}
          className="history-btn">
            Chat History
          </div>
          <br/>
        </div>
      </div>
    </div>
  );
}

export default Result;
