import { useState,useEffect } from "react";
import './App.css';
import LogoImg from "./assets/images/logo.png";
import MaxImg from "./assets/images/max.png";
//import ArrowDownImg from "./assets/images/arrow-down.png";
import MiyaImg from "./assets/images/miya.png";
import SelectionImg from "./assets/images/selection.png";
import DogGif from "./assets/images/dog.gif";
import CatGif from "./assets/images/cat.gif";
import moment from 'moment-timezone';

function Start({setGlobalCharacter,start,ongoing,setPage,gameResult,setShowResult}) {


  gameResult = gameResult || {};
  const [character, setCharacter] = useState("");
  const [loading, setLoading] = useState(false);
  const [timeLeft,setTimeLeft] = useState("");

  setInterval(() => {
    setTimeLeft(new Date(moment().tz("Asia/Singapore").endOf('day').diff(moment().tz("Asia/Singapore"))).toISOString().slice(11, 19));
  },1000);

  useEffect(() => { 
    //setGlobalCharacter(character);
  },[]);

  return (
    <div className="App-body" style={{backgroundColor:"#E6F1F1"}}>
      <div className="App-bg"></div>
      <img alt="logo" className="logo" src={LogoImg} />
      <br/>
      <br/>
      <div className="preloads">
        <img alt="max" src={LogoImg} />
        <img alt="max" src={MaxImg} />
        <img alt="max" src={MiyaImg} />
        <img alt="max" src={SelectionImg} />
        <img alt="max" src={DogGif} />
        <img alt="max" src={CatGif} />
        <img alt="" src="https://tinypets.s3.ap-southeast-1.amazonaws.com/chat/cat/normal.png"/>
        <img alt="" src="https://tinypets.s3.ap-southeast-1.amazonaws.com/chat/cat/happy.png"/>
        <img alt="" src="https://tinypets.s3.ap-southeast-1.amazonaws.com/chat/cat/excited.png"/>
        <img alt="" src="https://tinypets.s3.ap-southeast-1.amazonaws.com/chat/cat/sad.png"/>
        <img alt="" src="https://tinypets.s3.ap-southeast-1.amazonaws.com/chat/cat/disgusted.png "/>
        <img alt="" src="https://tinypets.s3.ap-southeast-1.amazonaws.com/chat/cat/grumpy.png"/>
        <img alt="" src="https://tinypets.s3.ap-southeast-1.amazonaws.com/chat/dog/happy.png"/>
        <img alt="" src="https://tinypets.s3.ap-southeast-1.amazonaws.com/chat/dog/excited.png"/>
        <img alt="" src="https://tinypets.s3.ap-southeast-1.amazonaws.com/chat/dog/sad.png"/>
        <img alt="" src="https://tinypets.s3.ap-southeast-1.amazonaws.com/chat/dog/disgusted.png"/>
        <img alt="" src="https://tinypets.s3.ap-southeast-1.amazonaws.com/chat/dog/grumpy.png"/>
      </div>
      <br/>
      <br/>
      <br/>
      {!ongoing && <h5 style={{position:"absolute",marginTop:-150,opacity:character===""?1:0}}>Pick a pet to play with</h5>}
      {(gameResult.status ==="lost" || gameResult.status==="won") &&<div className="come-back-balloon"> <div className="selection-balloon" style={{fontSize:10,maxWidth:250,marginBottom:-20}}>
        Come back tomorrow for more word-guessing game with us
        </div>
        {/*
        <br/>
        <img alt="" className="arrow-down-dog" src={ArrowDownImg}/>
        <img alt="" className="arrow-down-cat" src={ArrowDownImg}/>
  */}
        </div>}
      <div>
        <div 
          onClick={() => {
            if(ongoing) return;
            setCharacter("dog");
            setGlobalCharacter("dog");
          }}
          className="select-box max">
          <div className="selection-balloon" style={{opacity:character==="dog"?1:0}}>Play with me<br/>Woof!</div>
          <br/>
          <img alt="max" className={character==="dog"?"selection selected":"selection"} src={SelectionImg} />
          <span className="character-wrap">
            <img alt="max" className={loading && character==="dog" ? "max loading" : "max"} src={loading && character === "dog" ? DogGif : MaxImg} />
          </span>
          <span style={{fontSize:"20px",display:'block'}}>Max</span>
          <span style={{fontSize:"8px",display:'block'}}>The Doggie</span>
        </div>
        <div 
          onClick={() => {
            if(ongoing) return;
            setCharacter("cat");
            setGlobalCharacter("cat");
          }}
        className="select-box miya">
          <div className="selection-balloon" style={{opacity:character==="cat"?1:0}}>Aargh!<br/>Don't choose me!</div>
          <br/>
          <img alt="max" className={character==="cat"?"selection selected":"selection"} src={SelectionImg} />
          <span className="character-wrap">
            <img alt="miya" className={loading && character==="cat" ? "miya loading" : "miya"} src={loading && character === "cat" ? CatGif : MiyaImg} />
          </span>
          <span style={{fontSize:"20px",display:'block'}}>Mimi</span>
          <span style={{fontSize:"8px",display:'block'}}>The kitten</span>
        </div>
      </div>
      <br/>
      <div style={{zIndex:999}}>
        {(!gameResult || (gameResult && (gameResult.status !=="lost" && gameResult.status !=="won"))) &&
        <div 
          onClick={() => {
            if(ongoing) setPage("chat");
            else if(character==="" || loading) return
            else start();
            setLoading(true);
          }}
        className={((character==="" || loading) && !ongoing)?"start-btn disabled":"start-btn"}>
          {loading?"Loading...":(ongoing?"Resume":"Start Chat")}
          </div>
        }
        {gameResult && ( gameResult.status === "lost" || gameResult.status === "won" ) &&
          <div style={{display:"inline-block", marginTop:"calc(-100px + 10vh)"}}>
            <span style={{fontSize:8}}>You can play with them again in</span>
            <div
              className="start-btn disabled">
              <span style={{ color: "#4A2726" }}>
                {timeLeft.slice(0, 2)}h {timeLeft.slice(3, 5)}m {timeLeft.slice(6, 9)}s
              </span>
            </div>
          </div>
        }
      <br/>
      {["won", "lost"].indexOf(gameResult.status) > -1 &&
        <div
          className="result-btn home"
          onClick={() => {
            setPage("chat");
            setShowResult(true);
        }}>Result</div>
      }
      </div>
    </div>
  );
}

export default Start;
