import './App.css';
import MenuCloseImg from "./assets/images/menu-close.png";

function Feedback({show,character}) {

  return (
    <div className="App-modal">
      <div className="modal-bg"></div>
      <div className="inner">
          <img alt="menu" onClick={() => { show(false) }} className="result menu-close" src={MenuCloseImg} />
      <div style={{zIndex:999,margin:'auto',maxWidth:500,height:"calc(100vh - 150px)"}}>
          <iframe 
          title="feedbackform" 
          src="https://docs.google.com/forms/d/e/1FAIpQLSe4ua7-kOmbb-Ok64LqqnZjXYEPLNl7_iuKBwxSL9UKFK113w/viewform?embedded=true" 
          style={{width:"100%",height:"100%"}}
          frameborder="0" 
          marginheight="0" 
          marginwidth="0">Loading…</iframe>
      </div>
      </div>
    </div>
  );
}

export default Feedback;
