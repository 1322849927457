import { useState, useEffect } from "react";
import './App.css';
import Start from "./Start";
import Chat from "./Chat";
import Result from "./Result";
import Menu from "./Menu";
import Help from "./Help";
import About from "./About";
import Stats from "./Stats";
import Feedback from "./Feedback";
import MenuImg from "./assets/images/menu.png";
import HelpImg from "./assets/images/help.png";
import StatsImg from "./assets/images/stats.png";
import KickstarterImg from "./assets/images/kickstarter.png";
import { v4 as uuidv4 } from 'uuid';

function App() {

  const [page, setPage] = useState("start"); //start, chat
  const [character, setCharacter] = useState("");
  const [showMenu, setShowMenu] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [showAbout, setShowAbout] = useState(false);
  const [showStats, setShowStats] = useState(false);
  const [failed, setFailed] = useState(false);
  const [stats, setStats] = useState({});
  const [gameInfo, setGameInfo] = useState({});
  const [gameResult, setGameResult] = useState({});
  const [showFeedback, setShowFeedback] = useState(false);
  const [uuid, setUuid] = useState("");
  const [answer, setAnswer] = useState("");
  const [playerStats, setPlayerStats] = useState({});
  const [ongoing, setOngoing] = useState(false);
  const [starting, setStarting] = useState(false);


  const API = "https://api.42race.com/api/v1";
  //const API = "https://api-v2-sg-staging.42race.com/api/v1";

  useEffect(() => {
    if(window.location.pathname==="/reset"){
        localStorage.removeItem("uuid");
        window.location.href="/";
    }
    if (window.location.pathname !== "/gpt") {
      window.location.href = "/gpt";
    }
    let id = localStorage.getItem("uuid");
    if (id === "" || id === null || id === undefined) {
      setUuid(uuidv4());
      localStorage.setItem("uuid", uuid);
    } else {

      fetch(API + "/tinypetsxyz", {
        headers: { deviceid: id },
      })
        .then(res => res.json())
        .then((result) => {
          if(!result || !result.data || !result.data.game) return;
          setStats(result.data.game.stats);
          setGameInfo(result.data.game);
          setGameResult(result.data.result);
          setPlayerStats(result.data.player.stats);
          if (result.data.result) {
            setAnswer(result.data.result.item);
            if(starting) {
              setPage("chat");
              setStarting(false)
            }

            setCharacter(result.data.result.type);
            setOngoing(true);
          } 
        },
          (error) => { console.log(error); }
        )
    }
            // eslint-disable-next-line
  }, [page, uuid]);

  const selectPet = (pet) => {
    let id = localStorage.getItem("uuid");
    fetch(API + "/tinypetsxyz", {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        deviceid: id
      },
      body: JSON.stringify({
        gameID: gameInfo._id,
        type: pet
      }),
    })
      .then(res => res.json())
      .then((result) => {
        setPage("chat_temp");
      }, (error) => { console.log(error); }
      )
  }

  return (
    <div className="App">
      {showMenu && <Menu uuid={uuid} showAbout={() => { setShowAbout(true) }} showFeedback={() => { setShowFeedback(true) }} showMenu={(show) => { setShowMenu(show) }} />}
      {showResult && <Result answer={answer} failed={failed} setPage={setPage} playerStats={playerStats} gameResult={gameResult} gameInfo={gameInfo} stats={stats} uuid={uuid} character={character} showResult={(show) => { setShowResult(show) }} />}
      {showHelp && <Help uuid={uuid} character={character} show={(show) => { setShowHelp(show) }} />}
      {showAbout && <About uuid={uuid} character={character} show={(show) => { setShowAbout(show) }} />}
      {showStats && <Stats uuid={uuid} gameResult={gameResult} stats={playerStats} character={character} show={(show) => { setShowStats(show) }} />}
      {showFeedback && <Feedback uuid={uuid} character={character} show={(show) => { setShowFeedback(show) }} />}
      <div className="App-header">
        <div className="left-wrap">
          <img
            alt="menu"
            src={MenuImg}
            onClick={() => {
              setShowMenu(!showMenu);
            }} />
        </div>
        <div className="right-wrap">
          <img onClick={() => { setShowHelp(true) }} alt="help" src={HelpImg} />
          <img onClick={() => { setShowStats(true) }} alt="stats" src={StatsImg} />
          <img onClick={() => { setShowAbout(true) }} alt="kickstarter" src={KickstarterImg} />
        </div>
      </div>
      {page === "start" && <Start
      setShowResult={setShowResult}
        gameResult={gameResult}
        ongoing={ongoing}
        uuid={uuid}
        setPage={setPage}
        start={() => { 
          setStarting(true);
          selectPet(character); }}
        setGlobalCharacter={(character) => { setCharacter(character) }} />}
      {page === "chat" && <Chat 
      showResult={(show)=>{setShowResult(show)}} 
      setFailed={setFailed}
      setShowResult={setShowResult}
      setAnswer={setAnswer}
        setPage={setPage}
      gameResult={gameResult} 
      setGameResult={(result)=>{
        setGameResult(result);
      }}
      gameInfo={gameInfo} 
      uuid={uuid} 
      character={character} />}
    </div>
  );
}

export default App;
