import './App.css';
import MenuCloseImg from "./assets/images/menu-close.png";
import MiyaImg from "./assets/images/miya.png";
import MaxImg from "./assets/images/max.png";
import ArrowLeftImg from "./assets/images/arrow-left.png";

function Help({show,character}) {

  return (
    <div className="App-modal">
      <div className="modal-bg"></div>
      <div className="inner">
      <div style={{zIndex:999}}>
          <img alt="menu" onClick={() => { show(false) }} className="result menu-close" src={MenuCloseImg} />
          <div style={{fontSize:10,color:"#00A591"}}>How to play</div>
          <br/>
          <div style={{fontSize:8,color:"#4A2726"}}>Guess the items in 6 tries.</div>
          <br/>
          <div>
            <img alt="img" style={{height:178}} src={MaxImg}/>
            <img alt="img" style={{height:178}} src={MiyaImg}/>
          </div>
          <br/>
          <div className="help-balloons" style={{
            
          }}>
            <div className={"balloon left"}>
              <div className="inner">
                <img alt="img" className="arrow-left" src={ArrowLeftImg} />
                Choose one of us to play with
              </div>
            </div>
            <br/>
            <div className={"balloon left"}>
              <div className="inner">
                <img alt="img" className="arrow-left" src={ArrowLeftImg} />
                You can only play once a day
              </div>
            </div>
            <br/>
            <div className={"balloon left"}>
              <div className="inner">
                <img alt="img" className="arrow-left" src={ArrowLeftImg} />
                We will tell you everything you need to know
              </div>
            </div>
          </div>
          <br/>
          <div style={{fontSize:8,color:"#4A2726",marginBottom:10}}>Support us on kickstarter!</div>
          <div
            onClick={() => { window.open("https://www.kickstarter.com/projects/tinypets/tiny-pets-exercise-grow-and-collect-cute-pets","_blank")}}
            className="share-btn">Support</div>
      </div>
      </div>
    </div>
  );
}

export default Help;
