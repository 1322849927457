import { useState, useEffect } from "react";
import './App.css';
import SendImg from "./assets/images/send.png";
//import MiyaMaskedImg from "./assets/images/miya_masked.png";
//import MaxMaskedImg from "./assets/images/max_masked.png";
import TinyPetsImg from "./assets/images/tinypets.png";
import HeartImg from "./assets/images/heart.png";
import ArrowLeftImg from "./assets/images/arrow-left.png";
import ArrowRightImg from "./assets/images/arrow-right.png";
import BrokenHeartImg from "./assets/images/broken-heart.png";


const API = "https://api.42race.com/api/v1";
//const API = "https://api-v2-sg-staging.42race.com/api/v1";

let backgrounds = {
  start:"#E6F1F1",
  wrongAnswer:"#FFDCDA",
  closeAnswer:"#FFF7E8",
  correctAnswer:"#E2F2D2"
}

function Chat({character,gameInfo,gameResult,showResult,setGameResult,setFailed,setPage,setAnswer,setShowResult}) {

  gameResult = gameResult || {messages:[]};
  const [bg, setBg] = useState("start");
  const [loading, setLoading] = useState(false);
  const [chats, setChats] = useState(gameResult.messages);
  const [characterImg, setCharacterImg] = useState(gameResult.image);

  useEffect(() => {
    //setBg("wrongAnswer");
    if (gameResult.status === "won") {
      setBg("correctAnswer");
      showResult(true);
    } else if (gameResult.status === "ongoing") {
      setBg("start");
    }
    // eslint-disable-next-line
  }, [gameResult]);

  useEffect(() => {
    const element = document.getElementById("chat-history");
    element.scrollTop = element.scrollHeight;
  }, [chats]);


  const renderBalloons = (chats) => {
    chats = chats || [];
    return chats.map((chat, i) => {
      return (
        <div className={chat.role === "user" ? "balloon right" : "balloon left"}>
          <span className="sender">{chat.role === "assistant" ? (character === "dog") ? "Max" : "Mimi" : "You"}</span>
          <div className="inner">
            {chat.role === "assistant" && <img alt="img" className="arrow-left" src={ArrowLeftImg} />}
            {chat.role === "user" && <img alt="img" className="arrow-right" src={ArrowRightImg} />}
            {chat.content}</div>
        </div>
      );
    });
  }

  const sendMessage = (msg) => {
    if (loading || msg.trim() === "") return;
    let c = JSON.parse(JSON.stringify(chats));
    c.push({ role: "user", content: msg });
    setChats(c);
    setLoading(true);

    let id = localStorage.getItem("uuid");
    fetch(API + "/tinypetsxyz/" + gameResult._id, {
      method: "PUT",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        deviceid: id
      },
      body: JSON.stringify({
        message: msg
      }),
    })
      .then(res => res.json())
      .then((result) => {
        let d = JSON.parse(JSON.stringify(c));
        d.push({ role: "assistant", content: result.data.message });
        setChats(d);
        setCharacterImg(result.data.image);
        if (result.data.status === "won") {
          setBg("correctAnswer");
          setAnswer(result.data.item);
          setTimeout(() => {
            setGameResult(result.data);
            showResult(true);
          }, 3000);
        } else if (result.data.status === "ongoing") {
          setBg("wrongAnswer");
          setGameResult(result.data);
        } else if (result.data.status === "lost") {
          setAnswer(result.data.item);
          setBg("wrongAnswer");
          setTimeout(() => {
            setGameResult(result.data);
            setFailed(true);
            showResult(true);
          }, 3000);
        }

        let inp = document.getElementById("chat-input");
        inp.focus();
        setLoading(false);
      }, (error) => { 
        setLoading(false);
       }
      ).catch(()=>{
        setLoading(false);
      })
  }

  return (
    <div className="App-body" style={{ background: backgrounds[bg] }}>
      <div className="App-bg"></div>
      <div className="header-logo-wrap">
        <img 
          onClick={() => {
            setPage("start")
          }}
          alt="tinypets"
          className="tinypets-logo"
          src={TinyPetsImg} />
      </div>
      <div className="chat-avatar">
        <img alt="max" src={characterImg} />
        <div className="hearts">
          <img alt="broken" src={(gameResult.attempts > 0) ? BrokenHeartImg : HeartImg} />
          <img alt="broken" src={(gameResult.attempts > 1) ? BrokenHeartImg : HeartImg} />
          <img alt="broken" src={(gameResult.attempts > 2) ? BrokenHeartImg : HeartImg} />
          <img alt="broken" src={(gameResult.attempts > 3) ? BrokenHeartImg : HeartImg} />
          <img alt="broken" src={(gameResult.attempts > 4) ? BrokenHeartImg : HeartImg} />
          <img alt="broken" src={(gameResult.attempts > 5) ? BrokenHeartImg : HeartImg} />
        </div>
      </div>
      <div className="chat-history" id="chat-history">
        <div className="chat-wrap" id="chat-wrap">
          {renderBalloons(chats)}
        </div>
      </div>
      { gameResult.status === "ongoing" &&
      <div className="chat-field">
        {loading && <span className="typing-note">typing...</span>}
        <div className="chat-field-wrap">
          <div className="input-wrap">
            <input
              autocomplete='off'
              maxlength="100"
              id="chat-input"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  if(loading) return;
                  sendMessage(e.target.value);
                  e.target.value = "";
                }
              }}
              type="text"
            />
          </div>
          <div className="send-btn"
            onClick={() => {
              if(loading) return;
              let el = document.getElementById("chat-input");
              sendMessage(el.value);
              el.value = "";
            }}>
            <img alt="send" src={SendImg} />
          </div>
        </div>
      </div>
      }

      {["won", "lost"].indexOf(gameResult.status) > -1 &&
        <div className="chat-result-btn-wrap">
          <div
            className="result-btn"
            onClick={() => {
              setPage("chat");
              setShowResult(true);
            }}>Result</div>
        </div>
      }
    </div>
  );
}

export default Chat;
